window.Ticket = {
  ticketName: "",
  maximumSelection: 0,
  reservedSeats: [],
  reservedStands: {},

  getTicketName() {
    return this.ticketName;
  },

  setReservedSeats(seats) {
    this.reservedSeats = seats;
    this.reservedStands = {};
    this.reservedSeats.forEach((text) => {
      if (text.startsWith("stand")) {
        this.reservedStands[text] = this.reservedStands[text]
          ? this.reservedStands[text] + 1
          : 1;
      }
    });
  },

  setTicketName(newName) {
    this.ticketName = newName;
    window.ticketType.innerText = newName;
    window.Board.draw();
  },

  setMaximumSelection(value) {
    this.maximumSelection = value;
    window.totalToSelect.innerText = `0/${value}`;
  },
};
