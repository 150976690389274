import { Controller } from "stimulus";
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default class extends Controller {
  initialize() {
    console.log("swiper controller initialized");
    Swiper.use([Navigation, Autoplay]);
    this.mySwiper = new Swiper(".swiper-container", {
      // Optional parameters
      spaceBetween: 15,
      slidesPerView: 1.04,
      freeMode: true,
      speed: 500,
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        440: {
          slidesPerView: 1.3,
          spaceBetween: 20,


        },
        // when window width is >= 640px
        640: {
          slidesPerView: 2.08,
          spaceBetween: 20,


        },
        1024: {
          slidesPerView: 3.08,
          spaceBetween: 20,

        },
        1600: {
          slidesPerView: 4.1,
          spaceBetween: 25,

        },
      },
    });
    new Swiper(".mySwiper", {
      slidesPerView: 1,
      spaceBetween: 16,
      slidesPerGroup: 1,
      loop: true,
      loopFillGroupWithBlank: true,
      pagination: {
          el: ".swiper-pagination",
          clickable: true,
      },
      navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
      },
      breakpoints: {
          // when window width is >= 320px
          440: {
            slidesPerView: 1.3,
            spaceBetween: 20,


          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2.08,
            spaceBetween: 20,


          },
          1024: {
            slidesPerView: 3.08,
            spaceBetween: 20,

          },
          1600: {
            slidesPerView: 4.1,
            spaceBetween: 25,

          },

      }
  });
    new Swiper(".heroSwiper", {
      slidesPerView: 1,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      loop: true,
      loopFillGroupWithBlank: true,
      pagination: {
          el: ".swiper-pagination",
          clickable: true,
      },
      navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
      }
      
  });
  }
}
