import ApplicationController from './application_controller'
import axios from "axios";

const CustomApi = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  },
});

/* This is the custom StimulusReflex controller for the Products Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["overlay", "close", 'designUpcoming', 'endDate', 'startDate', 'table', 'report', 'search']
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  modal(event) {
    if(event.target == this.overlayTarget) {
      this.overlayTarget.setAttribute("style", "display: none;")
    }
  }

  closeModal() {
    this.overlayTarget.setAttribute("style", "display: none;")
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.stimulate('Products#search')
    }, 600)
  }

  tickets() {
    this.stimulate('Products#tickets')
  }

  beforeSearch() {
    this.productList.animate(
      this.fadeIn,
      this.fadeInTiming
    )
  }

  delete(e) {
    // this.stimulate('Products#delete')
    // const confirmed = confirm("Are you sure you want to delete this product?")
    // if (!confirmed) {
    //   console.log("canceled");
    //   e.preventDefault()
    // } else {
    //     e.preventDefault();
    //     console.log('Deleted')
    // }
  }

  get fadeIn() {
    return [
      { opacity: 0 },
      { opacity: 1 }
    ]
  }

  get fadeInTiming() {
    return { duration: 500 }
  }

  get productList() {
    return document.getElementById('products_list')
  }





  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="products" to your markup alongside
   * data-reflex="Products#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->Products#dance" data-controller="products">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Products#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // Assuming you create a "Products#dance" action in your Reflex class
  // you'll be able to use the following lifecycle methods:

  // beforeDance(element, reflex, noop, reflexId) {
  //  element.innerText = 'Putting dance shoes on...'
  // }

  // danceSuccess(element, reflex, noop, reflexId) {
  //   element.innerText = '\nDanced like no one was watching! Was someone watching?'
  // }

  // danceError(element, reflex, error, reflexId) {
  //   console.error('danceError', error);
  //   element.innerText = "\nCouldn\'t dance!"
  // }

  // afterDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nWhatever that was, it\'s over now.'
  // }

  // finalizeDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nNow, the cleanup can begin!'
  // }


  async runReport(e){
   e.preventDefault()
   const product = document.getElementById("product-name");
   const table = document.getElementById('datatable-buttons');
   const tableBody = document.getElementById('tbody');
   const wrapper = document.querySelector('.listtableWrapperScroll');
  const content = document.querySelectorAll('.tr');
  if(content){
    content.forEach(element => {
      element.remove();
    });
  };
  const startDate = new Date(this.startDateTarget.value);
  const endDate = new Date(this.endDateTarget.value);
  const msBetweenDates = Math.abs(startDate.getTime() - endDate.getTime());
  const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
  if(!this.validateDate(startDate, endDate, daysBetweenDates)){
    let data;
    if(e.target.id === 'run-product-report'){
      data = await this.fetchReport('', product.value, this.startDateTarget.value, this.endDateTarget.value)
    } else {
      data = await this.fetchReport(this.searchTarget.value, product.value, this.startDateTarget.value, this.endDateTarget.value)
    }
      if (Array.isArray(data) && data.length > 0){
        data.forEach(element => {
          let tr = document.createElement('tr');
          tr.classList.add('tr')
          for(let key in element) {
           let td = document.createElement('td');
           if(key === 'Date / Time UTC') {
             let formatedDate = element[key] + '.';
             td.innerText = formatedDate;
           } else {
             td.innerText = element[key];
           }
           td.classList.add('data-content')
           tr.appendChild(td);
           tableBody.appendChild(tr);
           wrapper.style.height = '300px';
           wrapper.style.overflowY = 'scroll';
           wrapper.backgroundColor = '#FFFFF2';
          }
        });
      } else {
        let tr = document.createElement('tr');
        tr.classList.add('tr')
        let td = document.createElement('td');
        td.innerText = 'No data found';
        td.classList.add('noData')
        tr.appendChild(td);
        tableBody.appendChild(tr);
      }
    }
  }

  async downloadExcel() {
    const product = document.getElementById("product-name");
    const table = document.getElementById('datatable_download');
    let res;
    if(product.value){
      const data = await CustomApi.get(`admin/filter_linked_products/${product.value}`);
      const options = await data.data;
      res = options
    }
    const date = new Date().toLocaleDateString().split('/').reverse().join('')
    const name = res.name+ ' ' + ' user sales list-' + date;
     var table2excel = new Table2Excel();
    table2excel.export(table, name);
  }

  validateDate(start, now, range){
    if(start > now){
      toastr.error('End date must be greater than start date');
      return true
    }
    if (range > 150) {
      toastr.error('Date must not go beyond 30 days');
      return true
    }
    return false
  }

  async fetchReport(search, product, startDate, endDate){
    const res = await CustomApi.get("admin/run_product_report", {
                params: {
                  product_id: product,
                  start_date: startDate,
                  end_date: endDate,
                  name: search
                  }
               })
      const data = await res.data
      return data
  }
}
