window.State = {
  globalParams: {},
  store: {
    display: "form",
  },

  getDisplay() {
    return this.store.display;
  },

  initializeZoom(zoomValue) {
    this.setScale(zoomValue);
    window.boardBox.scrollTo({
      top: 0,
      behaviour: "smooth",
    });
  },

  getScale() {
    return this.globalParams.scale || 1;
  },

  setScale(value) {
    value = Number(value);
    if (value < 1) value = 1;
    if (value > 10) value = 10;

    if (this.globalParams.scale == undefined) this.globalParams.scale = 1;

    board.style.transform = `scale(${value})`;
    this.setScroll({
      x: (board.clientWidth * (value - this.globalParams.scale)) / 2,
      y: (board.clientHeight * (value - this.globalParams.scale)) / 2,
    });

    this.globalParams.scale = value;
  },

  setScroll({ x, y }) {
    boardBox.scrollBy({
      left: x,
      top: y,
      behavior: "auto",
    });
  },
};
