import axios from "axios";

const CustomApi = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  },
});

window.Lib = {
  globalParams: {
    selectedSeats: [],
  },

  addToSelectedSeats(text) {
    if (this.globalParams.selectedSeats.length == maximumSeatSelection)
      this.globalParams.selectedSeats.shift();

    if (text.startsWith("stand")) {
      this.globalParams.selectedSeats = new Array(maximumSeatSelection).fill(
        text
      );
    } else if (text.startsWith("table") && !text.startsWith("table-seat")) {
      let arr = [];
      let letter = text.match(/table-\w+-T\d+/)[0];
      letter = letter.slice(6, letter.length);
      for (let i = 0; i < maximumSeatSelection; i++) {
        arr.push(`table-seat-${letter}-${i + 1}`);
      }
      this.globalParams.selectedSeats = [...arr];
    } else this.globalParams.selectedSeats.push(text);
    this.performSeatSelectionSideEffect(true);
  },

  colorMapItem(elem) {
    window.board
      .querySelectorAll("[id^='seat'] , [id^='table'], [id^='stand']")
      .forEach((elem) => {
        if (elem.tagName === "g")
          elem
            .querySelector("circle, rect")
            .setAttribute("fill", "transparent");
        else elem.setAttribute("fill", "transparent");
      });
    if (elem) {
      if (elem.tagName === "g")
        elem.querySelector("circle, rect").setAttribute("fill", "#97c7ff");
      else elem.setAttribute("fill", "#97c7ff");
    }
  },

  async createSeatingMapInDB(name, width, length) {
    let pvId = window.location.pathname.split("/").reverse()[0];
    let token = document.getElementById("token");

    const res = await fetch(`/admin/venue_seating_maps`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        authenticity_token: token.value,
        parent_venue_id: pvId,
        name,
        seating_map_json: JSON.stringify({
          size: { width, length },
          content: {},
        }),
        status: "draft",
        default: true,
      }),
    });

    let mapId = await res.json();
    console.log(mapId)

    return { pvId, mapId };
  },

  disableElements(...elems) {
    for (let elem of elems) {
      if (elem) elem.disabled = true;
    }
  },

  displayAreaForm() {
    let areaProps = {};

    const setAreaProps = (value) => {
      for (let key in value) {
        areaProps[key] = value[key];
      }

      window.Board.updateNewAreaProps(areaProps);
    };

    setAreaProps({
      id: window.Board.getUniqueAreaId(),
      name: "",
      type: "block",
      x: window.Board.getArenaSize().width / 50,
      y: window.Board.nextYPos,
      width: window.Board.getArenaSize().width / 10,
      length: window.Board.getArenaSize().length / 10,
    });

    window.toolForm.innerHTML = "";
    window.toolForm.append(
      window.AdminSeatComponent.areaToolForm(areaProps, setAreaProps)
    );

    let { x, y } = this.getPositionsForToolForm(window.toolArea);
    window.toolForm.style.transform = `translate(${x}px, ${y}px)`;
  },

  displayRowForm() {
    let rowProps = {};

    const setRowProps = (value) => {
      for (let key in value) {
        rowProps[key] = value[key];
      }
      window.Board.updateNewRowProps(rowProps);
    };

    setRowProps({
      id: window.Board.getUniqueRowId(),
      name: "",
      type: "seat",
      x: window.Board.getArenaSize().width / 50,
      y: window.Board.nextYPos,
      width:
        window.Board.getArenaSize().width -
        window.Board.getArenaSize().width / 25,
      rows: 2,
      rowColor: "none",
      rowCapacity: 1,
      chairWidth: 0.5,
      rowLength: 1,
      chairSpacing: 0.3,
      tableRowLength: 3,
      tableLength: 1,
      tableWidth: 1,
      tableSpacing: 0.5,
      tableChairWidth: 0.5,
      tableChairSpacing: 0.25,
      tableVerticalRadius: 0,
      tableHorizontalRadius: 0,
      tableChairPositions: ["north", "south", "east", "west"],
    });

    window.toolForm.innerHTML = "";
    window.toolForm.append(
      window.AdminSeatComponent.rowToolForm(rowProps, setRowProps)
    );

    let { x, y } = this.getPositionsForToolForm(window.toolRow);
    window.toolForm.style.transform = `translate(${x}px, ${y}px)`;

    window.Board.zParse();
  },

  emptySideBar() {
    window.sideBarBody.innerHTML =
      "<p class='empty-text'><span>Sidebar</span></p>";
  },

  enableElements(...elems) {
    for (let elem of elems) {
      elem.disabled = false;
    }
  },

  endPublishingProgress() {
    window.toolPublishJson.innerHTML = "Published \u2713";

    setTimeout(() => {
      window.toolPublishJson.innerHTML = "Publish";
    }, 2000);
  },

  endSavingProgress() {
    window.toolSaveJson.innerHTML = "Saved \u2713";

    setTimeout(() => {
      window.toolSaveJson.innerHTML = "Save Draft";
    }, 2000);
  },

  getLetterFromNum(num) {
    let a = ((num - 1) % 26) + 1;
    let b = Math.floor((num - 1) / 26) || "";

    if (typeof a == "number") a = String.fromCharCode(64 + a);
    if (typeof b == "number") b = String.fromCharCode(64 + b);

    return b.concat(a);
  },

  getRowColorRgb(rowColor) {
    if (!rowColor || rowColor == "none") return "rgba(255, 255, 255, 0)";
    if (rowColor == "grey") return "rgba(127, 127, 127, 0.1)";
    if (rowColor == "yellow") return "rgba(255, 255, 0, 0.1)";
    if (rowColor == "green") return "rgba(0, 255, 0, 0.1)";
    if (rowColor == "blue-green") return "rgba(0, 255, 255, 0.1)";
    if (rowColor == "blue") return "rgba(0, 0, 255, 0.1)";
    if (rowColor == "purple") return "rgba(255, 0, 255, 0.1)";
    if (rowColor == "red") return "rgba(255, 0, 0, 0.1)";
    return "rgba(255, 255, 255, 0)";
  },

  getPositionsForToolForm(relativeElem) {
    return {
      x: relativeElem.getBoundingClientRect().left + 2,
      y: window.header.getBoundingClientRect().bottom + 4,
    };
  },

  getBottomAndCenterPosition(elem) {
    let { left, width, bottom } = elem.getBoundingClientRect();

    return {
      x: left + width / 2,
      y: bottom,
    };
  },

  displayRowCapacityFormSection(capacityDiv, inputElem, rowProps, setRowProps) {
    capacityDiv.innerHTML = "";
    capacityDiv.append(
      window.AdminSeatComponent.rowCapacityForm(
        inputElem.value,
        rowProps,
        setRowProps
      )
    );
  },

  handleAreaClick(area) {
    let areaObj = window.Board.getAreaById(area.id.slice(5));
    if (!areaObj) return;

    window.Board.select(area.id.slice(5));
    areaObj.id = area.id.slice(5);
    this.showAreaEditor(areaObj);
  },

  handleRowClick(row) {
    let rowObj = window.Board.getRowById(row.id.slice(4));
    if (!rowObj) return;

    window.Board.select(row.id.slice(4));
    rowObj.id = row.id.slice(4);
    this.showRowEditor(rowObj);
  },

  isSeatSelected(text) {
    return this.globalParams.selectedSeats.includes(text);
  },

  moveSeatClipboard(elem) {
    let { x, y } = this.getBottomAndCenterPosition(elem);

    let elemId = "";
    let type = "";

    if (elem.id.startsWith("stand")) {
      elemId = elem.id.slice(6);
      type = "Stand";
    } else if (elem.id.startsWith("seat")) {
      elemId = elem.id.slice(5);
      type = "Seat";
    } else if (elem.id.startsWith("table-seat")) {
      elemId = elem.id.slice(11);
      type = "Table-seat";
    } else if (elem.id.startsWith("table")) {
      elemId = elem.id.slice(6);
      type = "Table";
    }

    seatClipBoard.innerHTML = "";
    seatClipBoard.append(window.AdminSeatComponent.seatClipboard(elem.id));

    if (
      y + seatClipBoard.getBoundingClientRect().height + 30 >
      window.innerHeight
    )
      y =
        y -
        elem.getBoundingClientRect().height -
        seatClipBoard.getBoundingClientRect().height -
        2;

    if (seatClipBoard.classList.contains("active"))
      seatClipBoard.style.transition = "";
    else seatClipBoard.style.transition = "opacity 500ms, visibility 500ms";

    let left = seatClipBoard.getBoundingClientRect().width / 2;

    seatClipBoard.style.transform = `translate(${x - left}px, ${y + 2}px)`;
    seatClipBoard.classList.add("active");
  },

  parseHtml(htmlstring) {
    let div = document.createElement("div");
    let fragement = new DocumentFragment();

    div.innerHTML = htmlstring;
    fragement.append(...div.children);

    return fragement;
  },

  performSeatSelectionSideEffect(added) {
    console.log(this.globalParams.selectedSeats);
    if (this.globalParams.selectedSeats.length === maximumSeatSelection)
      checkoutBtn.classList.remove("disabled");
    else checkoutBtn.classList.add("disabled");

    Map.draw();
    this.globalParams.selectedSeats.forEach((text) => {
      let elem = document.getElementById(text);
      if (elem.tagName === "g")
        elem.querySelector("circle, rect").setAttribute("fill", "#97c7ff");
      else elem.setAttribute("fill", "#97c7ff");
    });
  },

  async publishSeatingMap() {
    let mapId = new URLSearchParams(window.location.search).get("mapId");
    let token = document.getElementById("token");
    const res = await fetch(`/admin/update-seating-map-status/${mapId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        authenticity_token: token.value,
      }),
    });
  },

  removeSeatClipboard() {
    seatClipBoard.classList.remove("active");
  },

  removeSelectedSeat(text) {
    this.globalParams.selectedSeats = this.globalParams.selectedSeats.filter(
      (e) => e !== text
    );
    this.performSeatSelectionSideEffect(false);
  },

  returnToAdminPage() {
    console.log("returning to admin page");
  },

  rowIdSortFn(a, b) {
    if (!/Row-group-\d+/.test(a) || !/Row-group-\d+/.test(b)) return 0;
    let numA = a.match(/\d+/)[0];
    let numB = b.match(/\d+/)[0];

    return numA - numB;
  },

  async saveSeatingMapToDB() {
    let mapId = new URLSearchParams(window.location.search).get("mapId");
    let token = document.getElementById("token");

    await fetch(`/admin/venue_seating_maps/${mapId}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        seating_map_json: JSON.stringify(window.Board.getBoardJson()),
        authenticity_token: token.value,
      }),
    });
  },

  startPublishingProgress() {
    window.toolPublishJson.innerHTML = `Publishing <span style="font-size: 12px">\u2022\u2022\u2022</span>`;
  },

  startSavingProgress() {
    window.toolSaveJson.innerHTML = `Saving <span style="font-size: 12px">\u2022\u2022\u2022</span>`;
  },

  showAreaEditor(areaObj) {
    window.sideBarBody.innerHTML = "";
    window.sideBarBody.append(window.AdminSeatComponent.areaEditor(areaObj));
  },

  showRowEditor(rowObj) {
    window.sideBarBody.innerHTML = "";
    window.sideBarBody.append(window.AdminSeatComponent.rowEditor(rowObj));

    window.Board.zParse();
  },

  slideOutToolForm() {
    window.toolForm.style.transform = `translate(${
      -window.toolForm.getBoundingClientRect().width - 5
    }px, ${window.header.getBoundingClientRect().bottom + 4}px`;
  },

  startContinousScaleDownAfter500ms() {
    this.globalParams.scaleDownSetTime = setTimeout(() => {
      this.globalParams.scaleDownSetInerval = setInterval(() => {
        window.State.setScale(window.State.getScale() - 0.1);
      }, 25);
    }, 300);
  },

  startContinousScaleUpAfter500ms() {
    this.globalParams.scaleUpSetTime = setTimeout(() => {
      this.globalParams.scaleUpSetInerval = setInterval(() => {
        window.State.setScale(window.State.getScale() + 0.1);
      }, 25);
    }, 300);
  },

  stopContinousScale() {
    if (this.globalParams.scaleDownSetTime !== undefined)
      clearTimeout(this.globalParams.scaleDownSetTime);
    if (this.globalParams.scaleDownSetInerval !== undefined)
      clearInterval(this.globalParams.scaleDownSetInerval);
    if (this.globalParams.scaleUpSetTime !== undefined)
      clearTimeout(this.globalParams.scaleUpSetTime);
    if (this.globalParams.scaleUpSetInerval !== undefined)
      clearInterval(this.globalParams.scaleUpSetInerval);
  },

  updateSeatCount(seatNumber, rowId) {
    if (rowId && rowId.includes("--")) {
      let a  = rowId.length;
      rowId = rowId.slice(0, a - 3);
    }

    let noOfSeat_toolForm = document.getElementById("noOfSeat_toolForm");
    let noOfSeat_editor = document.getElementById(`noOfSeat_editor-${rowId}`);
    console.log(noOfSeat_editor, rowId);

    if (noOfSeat_toolForm) noOfSeat_toolForm.value = seatNumber;
    if (noOfSeat_editor) noOfSeat_editor.value = seatNumber;
  },

  unselectBoardComponents() {
    window.board.querySelectorAll("[id^='area']").forEach((area) => {
      window.Board.unselect(area.id.slice(5));
    });
    window.board.querySelectorAll("[id^='row']").forEach((row) => {
      window.Board.unselect(row.id.slice(4));
    });
  },
};
