import ApplicationController from "./application_controller";
import intlTelInput from "intl-tel-input";

/* This is the custom StimulusReflex controller for the Users Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
   */

  connect() {
    super.connect();
    // this.phoneInput = document.querySelector("#input-phone-number");
    // add your code here, if applicable
  }
  
  initialize() {
    this.input = document.querySelector("#phone-number");
    this.inputPhone = document.querySelector("#phone-number-input");
   
    // console.log(this.input.value)
  }

  numberInput(e) {
    this.inputPhone.value = window.iti.getNumber();
    this.inputPhone.dispatchEvent(new Event('input', {bubbles:true}));
  }

  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="users" to your markup alongside
   * data-reflex="Users#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->Users#dance" data-controller="users">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Users#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // Assuming you create a "Users#dance" action in your Reflex class
  // you'll be able to use the following lifecycle methods:

  // beforeDance(element, reflex, noop, reflexId) {
  //  element.innerText = 'Putting dance shoes on...'
  // }

  // danceSuccess(element, reflex, noop, reflexId) {
  //   element.innerText = '\nDanced like no one was watching! Was someone watching?'
  // }

  // danceError(element, reflex, error, reflexId) {
  //   console.error('danceError', error);
  //   element.innerText = "\nCouldn\'t dance!"
  // }

  // afterDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nWhatever that was, it\'s over now.'
  // }

  // finalizeDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nNow, the cleanup can begin!'
  // }
  updateNumber() {
    console.log("updateNumber");
    this.inputPhone.value = window.iti.getNumber();
    this.inputPhone.dispatchEvent(new Event('input', {bubbles:true}));
  }

  updateEmail() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.stimulate("Users#update_email");
    }, 100);
  }

  openPhoneVerificationModal(e) {
    const phoneModal = document.getElementById("phone-verification-modal");
    e.preventDefault();
    phoneModal.style.display = "block";
  }

  emailPhoneVerificationModal(e) {
    const emailModal = document.getElementById("email-verification-modal");
    e.preventDefault();
    emailModal.style.display = "block";
  }

  closeWindowModal(e) {
    const phoneModal = document.getElementById("phone-verification-modal");
    const emailModal = document.getElementById("email-verification-modal");
    if (e.target == phoneModal) {
      phoneModal.style.display = "none";
    } else if (e.target == emailModal) {
      emailModal.style.display = "none";
    }
  }

  closeEmailModal(e) {
    e.preventDefault();
    const emailModal = document.getElementById("email-verification-modal");
    emailModal.style.display = "none";
  }

  closePhoneModal(e) {
    e.preventDefault();
    const phoneModal = document.getElementById("phone-verification-modal");
    phoneModal.style.display = "none";
  }

  oldest(e){
    const url = window.location.href
    if(e.target.value === 'oldest'){
      window.location.href = url + '&sort=old'
    } else {
      window.location.href = url + '&sort=new'
    }
    console.log(e.target.value)
    console.log(window.location.href)
  }

  read(e){
    const url = window.location.pathname
    if(e.target.checked) {
      window.location.href = url + '?activity_type=unread'
    } else {
        window.location.href = url + '?activity_type=all'
    }
  }
}
