import { Controller } from "stimulus";
import Web3Modal from 'web3modal';
import Web3 from 'web3';
import Notiflix from "notiflix";
import Airdrop from "../utils/Airdrop.json";
import axios from 'axios';
import { airdropService, getWeb3Connection } from '../utils/dropToWallet';

const FormData = require('form-data');

const CustomApi = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  },
});
export default class extends Controller {
  initialize(){
    // this.calculateGasFee();
  }
  async claimAirdrop(e) {
    e.preventDefault();
    const id = document.getElementById("airdropToMe");
    const token = document.querySelector('[name=csrf-token]')
    const network = document.getElementById('airdropNetworkId')
    let network_id;
    if (network.value === 'ETH') {
      network_id = 1
    } else if (network.value === 'BSC') {
      network_id = 2
    } else {
      network_id = 3
    }
    try {
      const web3Modal = new Web3Modal();

      const connection = await web3Modal.connect();

      let providerInsatnce = new Web3(connection);

      const chainId = await providerInsatnce.eth.net.getId();
      let appChainIds;
      if ($('body').data('app-env') == 'production') {
        appChainIds = [1, 56, 137]
      } else {
        appChainIds = [80001, 5, 97]
      }

      if (typeof window.ethereum === 'undefined') {
        return Notiflix.Report.failure(
          'Connect to a provider/wallet',
          '',
          'Ok'
        );
      } else if (appChainIds.indexOf(chainId) < 0) {
        return Notiflix.Report.failure(
          'Invalid Network',
          'The Connected Network Is Invalid Or Not Supported',
          'Switch Network',
          function cb() {
            // openModal();
          }
        );
      }

      if (window?.ethereum?.selectedAddress === undefined) {
        return Notiflix.Report.failure(
          'Session Expired',
          'You are not login. Please reload!',
          'Reconnect',
          function cb() {
            //do something
          }
        );
      }

      const address = id.value;

      const AIRDROP = new providerInsatnce.eth.Contract(
        Airdrop.abi,
        `${address}`
      );

      Notiflix.Loading.arrows('Minting Airdrop...');

      const tx = await AIRDROP.methods
        .mint(`${1}`)
        .send({
          from: window.ethereum?.selectedAddress,
        });

      console.log(tx);

      const { tokenId } =
        tx?.events?.Transfer?.returnValues;

        Notiflix.Loading.change('Saving Airdrop...');

        await CustomApi.post('airdrop-to-me', {
          authenticity_token: token.content,
          metadata_hash: tx.transactionHash,
          token: tokenId,
          url: window.location.href,
          network_id: network_id
        });

        Notiflix.Report.success(
          "NFT Minted Successfully!!!"
          ,
          `Token Address ${tokenId}`,

          "Continue",
          function cb() {
            window.location.href = '/'
          }
        );

      var formData = new FormData()
      formData.append('transactionHash', tx?.transactionHash);

    } catch (error) {
      Notiflix.Loading.remove();

      Notiflix.Report.failure('Error while Creating Airdrop!', error.message);

      console.log('error in creating airdrop', error);

      console.log({...error});
    }
  }
  async generateWhitelist() {
    const address = document.querySelector('[name="airdrop_whitelist[address]"]');
    const id = document.getElementById('id');
    let placeholder = ''
    const data = await CustomApi.get(`admin/manage_nft/${id.value}`)
    const array = await data
    if (!array.data.success) {
      Notiflix.Loading.arrows('Generating Whitelist')
    } else {
      Notiflix.Loading.remove()
      array.data.nft.forEach(element => {
        placeholder = `${placeholder}\n` + element;
      });
      address.disabled = true
      address.value = placeholder.trim();
    }
  }
  manualWhitelist() {
    const address = document.querySelector('[name="airdrop_whitelist[address]"]');
    address.disabled = false;
    address.value = ''
  }

  async manageAirdrop(e) {
    e.preventDefault();
    const generate = document.querySelector('[name="airdrop_whitelist[address]"]');
    generate.disabled = false;
    const token = document.querySelector('[name=csrf-token]')
    const id = document.getElementById('id');
    const public = document.getElementById('togBtn')
    const contract_address = document.getElementById('contract_address');
    let address;
    if (generate.value.trim().length > 0) {
      address = generate.value.trim().split('\n')
    }
    try {
      const web3Modal = new Web3Modal();

      const connection = await web3Modal.connect();

      let providerInsatnce = new Web3(connection);

      const chainId = await providerInsatnce.eth.net.getId();

      let appChainIds;
      if ($('body').data('app-env') == 'production') {
        appChainIds = [1, 56, 137]
      } else {
        appChainIds = [5, 97, 80001]
      }

      if (typeof window.ethereum === 'undefined') {
        return Notiflix.Report.failure(
          'Connect to a provider/wallet',
          '',
          'Ok'
        );
      } else if (appChainIds.indexOf(chainId) < 0) {
        return Notiflix.Report.failure(
          'Invalid Network',
          'The Connected Network Is Invalid Or Not Supported',
          'Switch Network',
          function cb() {
            // openModal();
          }
        );
      }

      if (window?.ethereum?.selectedAddress === undefined) {
        return Notiflix.Report.failure(
          'Session Expired',
          'You are not login. Please reload! / Wallet Is Not Connected',
          'Reconnect',
          function cb() {
            //do something
          }
        );
      }

      const drop_address = contract_address.value;

      const AIRDROP = new providerInsatnce.eth.Contract(
        Airdrop.abi,
        `${drop_address}`
      );

      Notiflix.Loading.change('Uploading Whitelist...');

      const tx = await AIRDROP.methods
        .whitelistUsers(address)
        .send({
          from: window.ethereum?.selectedAddress,
        });

      console.log(tx);

        Notiflix.Loading.change('Saving Whitelist...');

    } catch (error) {
      Notiflix.Loading.remove();

      Notiflix.Report.failure('Error while Creating Whitelist!', error.message);

      console.log('error in creating whitelist', error);

      console.log({...error});
    }

    await CustomApi.post('admin/airdrop_whitelists', {
      authenticity_token: token.content,
      airdrop_id: id.value,
      address: address.join(',').replace(/\s/g, "")
    })

    Notiflix.Report.success(
      "Whitelist Uploaded Successfully!!!",

      'Click Continue to go back to airdrop page',

      "Continue",
      function cb() {
        window.location.href = '/admin/airdrops'
      }
    );
    console.log(address)
    console.log(token.content);
    console.log(id.value);
    console.log(public.checked)
  }

  static targets = ["eventSelect", "eventType"];
  connect() {
    if(this.hasEventSelectTarget){
      this.eventSelectTarget.focus
    }
    this.selectChange();
  }

  selectChange() {
    const changeBtn = document.getElementById('airdrop-category');
    const ticketType = document.getElementById('airdropTicketType');
    if(changeBtn && changeBtn.value === 'Tickets'){
      ticketType.classList.remove('d-none');
    } else {
      ticketType ? ticketType.classList.add('d-none') : null;
    }
  }
async dropToWallets(e) {
    e.preventDefault();
    const url = window.location.pathname.split('/')
    const networkAddress = document.querySelector('[name="airdrop_whitelist[address]"]').value.trim().split('\n');
    const contractAddress = document.getElementById('contract_address').value;
    const network = document.getElementById('network').value;
    const leftToMint = Number(document.getElementById('copies').value);
    const nextToken = Number(document.getElementById('next_token').value);
    const privateKey = document.getElementById('secret-key').value;
    const senderAddress = document.getElementById('sender-address').value;
    const token = document.querySelector('[name=csrf-token]')
    const id = url[url.length - 1]
    // console.log(token.content)
    // console.log(nextToken)

    // redirect to `/admin/airdrops/${id}` when a user clicks okay after dispatching or just reload the page to update the left to mint value

    try {


      Notiflix.Loading.arrows('Dispatching Airdrop...');


        await airdropService(network,networkAddress,contractAddress, nextToken ,senderAddress, privateKey );

        await CustomApi.put(`/admin/next_airdrop_token_update/${id}`, {
          authenticity_token: token.content,
          no_of_copies: leftToMint - networkAddress.length,
          next_airdrop_token: nextToken + networkAddress.length
        });

       Notiflix.Loading.remove();

        Notiflix.Report.info(
          "NFT Dispatched Successfully!!!"
          ,
          `View ${network} Explorer for full details.`,

          "Continue",
          function cb() {

          }
        );

    } catch (error) {
      Notiflix.Loading.remove();

      Notiflix.Report.failure('Error while Dispatching Airdrop!', error.message);

      console.log('error in creating airdrop', error);

      console.log({...error});
    }
  }

  async calculateGasFee(e){
    e.preventDefault();
    const networkAddress = document.querySelector('[name="airdrop_whitelist[address]"]');

    const network = document.getElementById('network').value;

    const noOfAddress = document.getElementById('size');
    const estimatedGasFee = document.getElementById('gass-fee')
    if(networkAddress.value) {
      const size = networkAddress.value.trim().split('\n').length;
      noOfAddress.innerText = size;

      // get transaction price

      try {

        const provider = getWeb3Connection(network);

        const gasPrice = await provider?.eth?.getGasPrice();

        const fee = provider.utils.fromWei(`${Number(gasPrice) * 21000}`, 'ether')

        estimatedGasFee.innerText = `${fee * Number(size)} ${network}` ;

        window.location.href = window.location.pathname + '#airdrop'

      } catch (error) {
        Notiflix.Loading.remove();

        Notiflix.Report.failure('Error while Dispatching Airdrop!', error.message);

        console.log('error in creating airdrop', error);

        console.log({...error});

        return;
      }
    } else {
       toastr.error('You must include address')
    }
  }

}
