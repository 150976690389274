// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
ActiveStorage.start();

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// import "bootstrap"
import "stylesheets/application.scss";

require("intl-tel-input");
require("packs/jquery-3.5.1.min");
require("packs/bootstrap.bundle.min");
require("packs/owl.carousel");
require("packs/isotope.pkgd.min");
require("packs/file_script");
require("packs/formatter.js");
require("packs/ethereum/web3.js");
require("packs/common.js");
require("packs/collections.js");
require("packs/seatingMap/index.js");
require("packs/seatingMap/board.js");
require("packs/seatingMap/index.js");
require("packs/seatingMap/lib.js");
require("packs/seatingMap/state.js");
require("packs/seatingMap/domEventListeners.js");
require("packs/seatingMap/components.js");
require("packs/customerSeatingMap/index.js");
require("packs/customerSeatingMap/board.js");
require("packs/customerSeatingMap/domEventListeners.js");
require("packs/customerSeatingMap/components.js");
require("packs/customerSeatingMap/lib.js");
require("packs/customerSeatingMap/state.js");
require("packs/customerSeatingMap/ticket.js");
require("packs/jquery.magnific-popup.min");
require("packs/select2.min");
require("packs/smooth-scrollbar.js");
require("packs/jquery.countdown.min");
window.intlTelInput = require("intl-tel-input");

global.toastr = require("toastr");
global.BigNumber = require("bignumber.js");

setTimeout(function () {
  require("packs/chosen.jquery");
  require("packs/script");
  require("packs/main");
}, 500);

import "controllers";
document.addEventListener("DOMContentLoaded", function(event) {
  const input = document.querySelector("#phone-number");
  if (input) {
    var iti = window.intlTelInput(input, {
      separateDialCode: true,
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js",
      formatOnDisplay: true,
    });
  }
  window.iti = iti;  
});
