import Web3 from 'web3';
import axios from 'axios';
import Web3Modal, { injected } from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";



const tokenURIPrefix = gon.tokenURIPrefix;
const transferProxyContractAddress = gon.transferProxyContractAddress;
const tokenAddress = gon.tokenAddress;
const tradeContractAddress = gon.tradeContractAddress;
let account;
let isPolygon = false;
let isPolygonLive = true;
let oriRoute = '/';

async function loadWeb3(wallet) {
  if (window.ethereum) {

    let providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "05f69a0ebdec4bc0862c35634edbe172" // required
        }
      },
    };

    const modalProps = {
      cacheProvider: true, // optional
      providerOptions, // required
      disableInjectedProvider: true,
    }

    if(wallet === 'injected') {
      modalProps.disableInjectedProvider = false;
    }

    // const web3Modal = new Web3Modal(modalProps);

    const web3Modal = new Web3Modal();

    if(wallet) {
      web3Modal.clearCachedProvider();
    }

    const  provider = await web3Modal.connect();

    const providerInstance = new Web3(provider);

    window.web3 = providerInstance;

    const chainId = await providerInstance.eth.net.getId();

    if(chainId === 80001 || chainId === 137) {

      isPolygon = true;

    }

    if(chainId === 80001) {

      isPolygonLive = false;

    }

    // window.ethereum.enable();
    // await window.ethereum.request({ method: 'eth_requestAccounts' }); // eth_requestAccounts
  }
}

async function createUserSession(address, balance, destroySession, type = null) {
  const config = {
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const resp = await axios
    .post(
      `/sessions`,
      {
        address: address,
        balance: balance,
        destroy_session: destroySession,
        type,
      },
      config
    )
    .then((response) => {
      return resp;
    })
    .catch((err) => {
      console.log('User Session Create Error', err);
    });
  return resp;
}

async function destroyUserSession(address) {
  const config = {
    data: {},
    headers: {
      'X-CSRF-TOKEN': $('[name="csrf-token"]')[0].content,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const resp = axios
    .delete(`/sessions/${address}`, config)
    .then((response) => response)
    .catch((err) => console.log('Session Error: ', err));
  return resp;
}

function updateTokenId(tokenId, collectionId, hash) {
  var request = $.ajax({
    url: `/collections/${collectionId}/update_token_id`,
    async: false,
    type: 'POST',
    data: { tokenId: tokenId, collectionId: collectionId, tx_id: hash },
    dataType: 'script',
  });
  request.done(function (msg) {
    console.log('Token Id updated.');
  });
  request.fail(function (jqXHR, textStatus) {
    console.log('Failed to update token id');
  });
}

function createContract(formData) {
  var request = $.ajax({
    url: '/users/create_contract',
    async: false,
    type: 'POST',
    data: formData,
    dataType: 'script',
    processData: false,
    contentType: false,
    cache: false,
  });
  request.done(function (msg) {
    console.log('Token Id updated.');
  });
  request.fail(function (jqXHR, textStatus) {
    console.log('Failed to update token id');
  });
}

function updateCollectionBuy(
  collectionId,
  quantity,
  transactionHash,
  tokenId = 0
) {
  var request = $.ajax({
    url: '/collections/' + collectionId + '/buy',
    type: 'POST',
    async: false,
    data: { quantity: quantity, transaction_hash: transactionHash, tokenId },
    dataType: 'script',
    success: function (respVal) {
      console.log(respVal);
    },
  });
}

function updateCollectionSell(
  collectionId,
  buyerAddress,
  bidId,
  transactionHash,
  tokenId = 0
) {
  var request = $.ajax({
    url: '/collections/' + collectionId + '/sell',
    type: 'POST',
    async: false,
    data: {
      address: buyerAddress,
      bid_id: bidId,
      transaction_hash: transactionHash,
      tokenId,
    },
    dataType: 'script',
    success: function (respVal) {
      console.log(respVal);
    },
  });
}

function updateOwnerTransfer(
  collectionId,
  recipientAddress,
  transactionHash,
  supply
) {
  var request = $.ajax({
    url: '/collections/' + collectionId + '/owner_transfer',
    type: 'POST',
    async: false,
    data: {
      recipient_address: recipientAddress,
      transaction_hash: transactionHash,
      supply: supply,
    },
    dataType: 'script',
    success: function (respVal) {
      console.log(respVal);
    },
  });
}

function updateBurn(collectionId, transactionHash, supply) {
  var request = $.ajax({
    url: '/collections/' + collectionId + '/burn',
    type: 'POST',
    async: false,
    data: { transaction_hash: transactionHash, supply: supply },
    dataType: 'script',
    success: function (respVal) {
      console.log(respVal);
    },
  });
}

async function isValidUser(address, token) {
  const config = {
    headers: {
      'X-CSRF-TOKEN': token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const resp = await axios
    .get(
      `/sessions/valid_user`,
      { params: { address: address, authenticity_token: token } },
      config
    )
    .then((response) => {
      console.log('validate user', response);
      return response.data;
    })
    .catch((err) => {
      console.log('User Session Validate Error', err);
    });
  return resp;
}

function placeBid(collectionId, sign, quantity, bidDetails) {
  var request = $.ajax({
    url: `/collections/${collectionId}/bid`,
    type: 'POST',
    async: false,
    data: { sign: sign, quantity: quantity, details: bidDetails },
    dataType: 'script',
  });
  request.done(function (msg) {
    console.log('Bidding success.');
  });
  request.fail(function (jqXHR, textStatus) {
    console.log('Bidding failed. Please contact support');
  });
}

function signMetadataHash(collectionId, contractAddress) {
  var sign;
  var request = $.ajax({
    url: `/collections/${collectionId}/sign_metadata_hash`,
    type: 'POST',
    async: false,
    data: { contract_address: contractAddress },
    dataType: 'json',
  });
  request.done(function (msg) {
    console.log(msg);
    sign = msg['signature'];
  });
  request.fail(function (jqXHR, textStatus) {
    console.log('Bidding failed. Please contact support');
  });
  return sign;
}

function sign_metadata_with_creator(
  creator_address,
  tokenURI,
  collectionId,
  trade_address = nil
) {
  var sign;
  $.ajax({
    url: `/collections/${collectionId}/sign_metadata_with_creator`,
    type: 'POST',
    async: false,
    data: {
      address: creator_address,
      tokenURI: tokenURI,
      trade_address: trade_address,
    },
    dataType: 'json',
  })
    .done(function (msg) {
      console.log(msg);
      sign = msg['signature'];
    })
    .fail(function (jqXHR, textStatus) {
      console.log('Bidding failed. Please contact support');
    });
  return sign;
}

function updateSignature(collectionId, sign) {
  var request = $.ajax({
    url: `/collections/${collectionId}/sign_fixed_price`,
    type: 'POST',
    async: false,
    data: { sign: sign },
    dataType: 'script',
  });
  request.done(function (msg) {
    console.log('Signature updated.');
  });
  request.fail(function (jqXHR, textStatus) {
    console.log('Signature update failed. Please contact support');
  });
}

window.approveCollection = function approveCollection(collectionId) {
  $.ajax({
    url: `/collections/${collectionId}/approve`,
    type: 'POST',
    async: false,
    dataType: 'script',
  })
    .done(function (msg) {
      console.log('Collection updated.');
    })
    .fail(function (jqXHR, textStatus) {
      console.log('Collection update failed. Please contact support');
    });
};

window.getContractABIAndBytecode = function getContractABIAndBytecode(
  contractAddress,
  type,
  shared = true
) {
  var res;
  var request = $.ajax({
    async: false,
    url: '/contract_abi',
    type: 'GET',
    data: { contract_address: contractAddress, type: type, shared: shared },
    dataType: 'json',
  });

  request.done(function (msg) {
    res = msg;
  });

  request.fail(function (jqXHR, textStatus) {
    console.log(textStatus);
  });
  return res;
};

function splitSign(sign) {
  sign = sign.slice(2);
  var r = `0x${sign.slice(0, 64)}`;
  var s = `0x${sign.slice(64, 128)}`;
  var v = web3.utils.toDecimal(`0x${sign.slice(128, 130)}`);
  return [v, r, s];
}

window.getContract = async function getContract(
  contractAddress,
  type,
  shared = true
) {
  console.log(contractAddress, type, shared);
  var res = getContractABIAndBytecode(contractAddress, type, shared);
  var contractObj = await new window.web3.eth.Contract(
    res['compiled_contract_details']['abi'],
    contractAddress
  );
  console.log(contractObj);
  return contractObj;
};

window.createCollectible721 = async function createCollectible721(
  contractAddress,
  tokenURI,
  collectionWellatAddress,
  collectionRoyalties,
  collectionId,
  sharedCollection
) {
  try {
    var account = window.ethereum.selectedAddress;
    console.log(account, contractAddress, 'nft721', sharedCollection);
    var gasPrices = await estimateGas();
    let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }

    let tokenId;
    if (sharedCollection) {
      const contract = await getContract(
        tradeContractAddress,
        'trade',
        sharedCollection
      );
      var sign = await signMetadataHash(collectionId, contractAddress);
      var signStruct = splitSign(sign);
      console.log(
        tokenURI,
        collectionWellatAddress,
        collectionRoyalties,
        signStruct
      );
      const nftType = 1;
      var txn = await contract.methods
        .mint(
          contractAddress,
          nftType,
          tokenURI,
          1,
          collectionWellatAddress,
          collectionRoyalties
        )
        .send({
          from: account,
          // gas: 516883,
          ...polygonSelect
        });
      tokenId = web3.utils.toDecimal(txn.events[0].raw.topics[3].slice(0, 66));
    } else {
      console.log(window.contract721);
      window.contract721 = await getContract(
        contractAddress,
        'nft721',
        sharedCollection
      );
      var txn = await window.contract721.methods
        .createCollectible(
          tokenURI,
          collectionWellatAddress,
          collectionRoyalties
        )
        .send({
          from: account,
          // gas: 516883,
          ...polygonSelect
        });
      tokenId = txn.events.Transfer.returnValues['tokenId'];
    }
    console.log(tokenId);
    await updateTokenId(tokenId, collectionId);
    return window.collectionMintSuccess(collectionId);
  } catch (err) {
    console.error(err);
    return window.collectionMintFailed(err['message']);
  }
};

window.createCollectible1155 = async function createCollectible1155(
  contractAddress,
  supply,
  tokenURI,
  collectionWellatAddress,
  collectionRoyalties,
  collectionId,
  sharedCollection
) {
  try {
    var account = window.ethereum.selectedAddress;
    console.log(contractAddress, 'nft1155', sharedCollection);
    console.log(account);
    var gasPrices = await estimateGas();
    let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }

    let tokenId;
    if (sharedCollection) {
      const contract = await getContract(
        tradeContractAddress,
        'trade',
        sharedCollection
      );
      var sign = await signMetadataHash(collectionId, contractAddress);
      var signStruct = splitSign(sign);
      console.log('signStruct:', signStruct);
      const nftType = 0;
      var txn = await contract.methods
        .mint(
          contractAddress,
          nftType,
          tokenURI,
          supply,
          collectionWellatAddress,
          collectionRoyalties
        )
        .send({
          from: account,
          // gas: 516883,
          ...polygonSelect
        });
      tokenId = web3.utils.toDecimal(txn.events[0].raw.data.slice(0, 66));
    } else {
      window.contract1155 = await getContract(
        contractAddress,
        'nft1155',
        sharedCollection
      );
      var txn = await window.contract1155.methods
        .mint(tokenURI, supply, collectionWellatAddress, collectionRoyalties)
        .send({
          from: account,
          // gas: 516883,
          ...polygonSelect
        });
      tokenId = txn.events.TransferSingle.returnValues['id'];
    }

    console.log(tokenId);
    await updateTokenId(tokenId, collectionId);
    return window.collectionMintSuccess(collectionId);
  } catch (err) {
    console.error(err);
    return window.collectionMintFailed(err['message']);
  }
};

window.deployContract = async function deployContract(
  abi,
  bytecode,
  name,
  symbol,
  contractType,
  collectionId,
  attachment,
  description,
  cover
) {
  const contractDeploy = new window.web3.eth.Contract(abi);
  var contractAddress;
  var account = window.ethereum.selectedAddress;
  contractDeploy
    .deploy({
      data: bytecode,
      arguments: [name, symbol, tokenURIPrefix],
    })
    .send({
      from: account,
    })
    .then((deployment) => {
      console.log('Contract was deployed at the following address:');
      console.log(deployment.options.address);
      contractAddress = deployment.options.address;
      $('#nft_contract_address').val(contractAddress);
      $('#nft_contract_address').val(contractAddress);
      let formData = new FormData();
      formData.append('file', attachment);
      formData.append('name', name);
      formData.append('symbol', symbol);
      formData.append('contract_address', contractAddress);
      formData.append('contract_type', contractType);
      formData.append('collection_id', collectionId);
      formData.append('description', description);
      formData.append('cover', cover);
      createContract(formData);
      window.contractDeploySuccess(contractAddress, contractType);
    })
    .catch((err) => {
      console.error(err);
      window.contractDeployFailed(err['message']);
    });
};

window.approveNFT = async function approveNFT(
  contractType,
  contractAddress,
  sharedCollection,
  sendBackTo = 'collection'
) {
  try {
    console.log(contractAddress, contractType, sharedCollection);
    var account = window.ethereum.selectedAddress;
    window.contract = await getContract(
      contractAddress,
      contractType,
      sharedCollection
    );
    var isApproved = await window.contract.methods
      .isApprovedForAll(account, transferProxyContractAddress)
      .call();
      var gasPrices = await estimateGas();
      let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }

    if (!isApproved) {
      var receipt = await window.contract.methods
        .setApprovalForAll(transferProxyContractAddress, true)
        .send({
          from: account,
          // gas: 516883,
          ...polygonSelect
        });
    }
    if (sendBackTo == 'executeBid') {
      return window.approveBidSuccess();
    } else {
      return window.collectionApproveSuccess(contractType);
    }
  } catch (err) {
    console.error(err);
    if (sendBackTo == 'executeBid') {
      return window.approveBidFailed(err['message']);
    } else {
      return window.collectionApproveFailed(err['message']);
    }
  }
};

window.approveResaleNFT = async function approveResaleNFT(
  contractType,
  contractAddress,
  sharedCollection
) {
  try {
    console.log(contractAddress, contractType, sharedCollection);
    var account = window.ethereum.selectedAddress;
    window.contract = await getContract(
      contractAddress,
      contractType,
      sharedCollection
    );
    var isApproved = await window.contract.methods
      .isApprovedForAll(account, transferProxyContractAddress)
      .call();
      var gasPrices = await estimateGas();
      let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }

    if (!isApproved) {
      var receipt = await window.contract.methods
        .setApprovalForAll(transferProxyContractAddress, true)
        .send({
          from: account,
          // gas: 516883,
          ...polygonSelect
        });
    }
    return window.approveResaleSuccess(contractType);
  } catch (err) {
    console.error(err);
    return window.approveResaleFailed(err['message']);
  }
};

//TODO: OPTIMIZE
window.isApprovedNFT = async function isApprovedNFT(
  contractType,
  contractAddress
) {
  try {
    var contract = await getContract(contractAddress, contractType);
    var account = window.ethereum.selectedAddress;
    var isApproved = await contract.methods
      .isApprovedForAll(account, transferProxyContractAddress)
      .call();
    return isApproved;
  } catch (err) {
    console.error(err);
  }
};

window.burnNFT = async function burnNFT(
  contractType,
  contractAddress,
  tokenId,
  supply = 1,
  collectionId,
  sharedCollection
) {
  try {
    var contract = await getContract(
      contractAddress,
      contractType,
      sharedCollection
    );
    var account = window.ethereum.selectedAddress;
    var gasPrices = await estimateGas();
    let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }

    if (contractType == 'nft721') {
      var receipt = await contract.methods.burn(tokenId).send({
        from: account,
        // gas: 516883,
        ...polygonSelect
      });
    } else if (contractType == 'nft1155') {
      var receipt = await contract.methods.burn(tokenId, supply).send({
        from: account,
        // gas: 516883,
       ...polygonSelect
      });
    }
    await updateBurn(collectionId, receipt.transactionHash, supply);
    return window.burnSuccess(receipt.transactionHash);
  } catch (err) {
    console.error(err);
    return window.burnFailed(err['message']);
  }
};

window.directTransferNFT = async function directTransferNFT(
  contractType,
  contractAddress,
  recipientAddress,
  tokenId,
  supply = 1,
  shared,
  collectionId
) {
  try {
    console.log(
      contractType,
      contractAddress,
      recipientAddress,
      tokenId,
      supply,
      shared,
      collectionId
    );
    var contract = await getContract(contractAddress, contractType, shared);
    var account = window.ethereum.selectedAddress;
    var gasPrices = await estimateGas();
    let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }

    if (contractType == 'nft721') {
      var receipt = await contract.methods
        .safeTransferFrom(account, recipientAddress, tokenId)
        .send({
          from: account,
          // gas: 516883,
          ...polygonSelect
        });
    } else if (contractType == 'nft1155') {
      // TODO: Analyse and use proper one in future
      var tempData =
        '0x6d6168616d000000000000000000000000000000000000000000000000000000';
      var receipt = await contract.methods
        .safeTransferFrom(account, recipientAddress, tokenId, supply, tempData)
        .send({
          from: account,
          // gas: 516883,
         ...polygonSelect
        });
    }
    await updateOwnerTransfer(
      collectionId,
      recipientAddress,
      receipt.transactionHash,
      supply
    );
    return window.directTransferSuccess(receipt.transactionHash, collectionId);
  } catch (err) {
    console.error(err);
    return window.directTransferFailed(err['message']);
  }
};

window.approveERC20 = async function approveERC20(
  contractAddress,
  contractType,
  amount,
  decimals = 18,
  sendBackTo = 'Bid'
) {
  try {
    console.log(
      contractAddress,
      contractType,
      gon.collection_data['contract_shared']
    );
    amount = roundNumber(mulBy(amount, 10 ** decimals), 0);
    // var approvedAmount = await approvedTokenBalance(contractAddress);
    // var tokenBalance = await tokenBalance(contractAddress, decimals);
    // if((parseInt(amount)+parseInt(approvedAmount)) >= tokenBalance) {
    //   console.log("Insufficient amount to approve.")
    //   return;
    // }
    var compiledContractDetails = getContractABIAndBytecode(
      contractAddress,
      contractType,
      gon.collection_data['contract_shared']
    );
    var abi = compiledContractDetails['compiled_contract_details']['abi'];
    var contract = await new window.web3.eth.Contract(abi, contractAddress);
    var account = window.ethereum.selectedAddress;
    console.log(compiledContractDetails);
    console.log(account);
    console.log(contractAddress);
    var gasPrices = await estimateGas();
    let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }
    var receipt = await contract.methods
      .approve(transferProxyContractAddress, amount)
      .send({
        from: account,
        // gas: 516883,
        ...polygonSelect
      });
    if (sendBackTo == 'Buy') {
      return window.buyApproveSuccess(receipt.transactionHash, contractAddress);
    } else {
      return window.bidApproveSuccess(receipt.transactionHash, contractAddress);
    }
  } catch (err) {
    console.error(err);
    if (sendBackTo == 'Buy') {
      return window.buyApproveFailed(err['message']);
    } else {
      return window.bidApproveFailed(err['message']);
    }
  }
};

window.approvedTokenBalance = async function approvedTokenBalance(
  contractAddress
) {
  var contract = await getContract(contractAddress, 'erc20', false);
  var account = window.ethereum.selectedAddress;
  var balance = await contract.methods
    .allowance(account, transferProxyContractAddress)
    .call();
  return balance;
};

window.convertToken = async function convertToken(
  amount,
  sendBackTo = 'Bid',
  decimals = 18
) {
  try {
    amount = roundNumber(mulBy(amount, 10 ** decimals), 0);
    var compiledContractDetails = getContractABIAndBytecode(
      tokenAddress,
      'erc20'
    );
    var abi = compiledContractDetails['compiled_contract_details']['abi'];

    var contract = await new window.web3.eth.Contract(abi, tokenAddress);
    var account = window.ethereum.selectedAddress;
    var gasPrices = await estimateGas();
    let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }

    console.log(gasPrices);
    var receipt = await contract.methods.deposit().send({
      from: account,
      value: amount,
      // gas: 516883,
      ...polygonSelect
    });

    if (sendBackTo == 'Buy') {
      return window.buyConvertSuccess(receipt.transactionHash);
    } else {
      return window.bidConvertSuccess(receipt.transactionHash);
    }
  } catch (err) {
    console.error(err);
    if (sendBackTo == 'Buy') {
      return window.bidConvertFailed(err['message']);
    } else {
      return window.bidConvertFailed(err['message']);
    }
  }
};

window.updateBuyerServiceFee = async function updateBuyerServiceFee(
  buyerFeePermille
) {
  try {
    var compiledContractDetails = getContractABIAndBytecode(
      tradeContractAddress,
      'trade'
    );
    var abi = compiledContractDetails['compiled_contract_details']['abi'];
    var contract = await new window.web3.eth.Contract(
      abi,
      tradeContractAddress
    );
    var account = window.ethereum.selectedAddress;
    var gasPrices = await estimateGas();
    let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }

    console.log(gasPrices);
    var receipt = await contract.methods
      .setBuyerServiceFee(buyerFeePermille)
      .send({
        from: account,
        // gas: 516883,
        ...polygonSelect
      });
    console.log('buyer', String(receipt.status));
    if (String(receipt.status) === 'true') {
      $('form#fee_form').submit();
      $('div.loading-gif.displayInMiddle').hide();
    }
  } catch (err) {
    return false;
  }
};

window.updateSellerServiceFee = async function updateSellerServiceFee(
  sellerFeePermille
) {
  try {
    var compiledContractDetails = getContractABIAndBytecode(
      tradeContractAddress,
      'trade'
    );
    var abi = compiledContractDetails['compiled_contract_details']['abi'];
    var contract = await new window.web3.eth.Contract(
      abi,
      tradeContractAddress
    );
    var account = window.ethereum.selectedAddress;
    var gasPrices = await estimateGas();
    let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }

    console.log(gasPrices);
    var receipt = await contract.methods
      .setSellerServiceFee(sellerFeePermille)
      .send({
        from: account,
        // gas: 516883,
        ...polygonSelect
      });
    console.log('seller', receipt);
    console.log(String(receipt.status));
    if (String(receipt.status) === 'true') {
      $('form#fee_form').submit();
      $('div.loading-gif.displayInMiddle').hide();
    }
  } catch (err) {
    console.error(err);
  }
};

window.bidAsset = async function bidAsset(
  assetAddress,
  tokenId,
  qty = 1,
  amount,
  payingTokenAddress,
  decimals = 18,
  collectionId,
  bidPayAmt
) {
  try {
    console.log(
      assetAddress,
      tokenId,
      qty,
      amount,
      payingTokenAddress,
      decimals,
      collectionId,
      bidPayAmt
    );
    var amountInDec = roundNumber(mulBy(amount, 10 ** decimals), 0);
    console.log(amountInDec);
    console.log(assetAddress, tokenId, payingTokenAddress, amountInDec, qty);
    var messageHash = window.web3.utils.soliditySha3(
      assetAddress,
      tokenId,
      payingTokenAddress,
      amountInDec,
      qty
    );
    console.log(messageHash);
    var account = window.ethereum.selectedAddress;
    const signature = await window.web3.eth.personal.sign(messageHash, account);
    await placeBid(collectionId, signature, qty, {
      asset_address: assetAddress,
      token_id: tokenId,
      quantity: qty,
      amount: bidPayAmt,
      amount_with_fee: amount,
      payment_token_address: payingTokenAddress,
      payment_token_decimals: decimals,
    });
    return window.bidSignSuccess(collectionId);
  } catch (err) {
    console.error(err);
    return window.bidSignFailed(err['message']);
  }
};

window.signMessage = async function signMessage(msg) {
  try {
    var account = window.ethereum.selectedAddress;
    var sign = await window.web3.eth.personal.sign(msg, account);
    return sign;
  } catch (err) {
    console.log(err);
    return '';
  }
};

window.signSellOrder = async function signSellOrder(
  amount,
  decimals,
  paymentAssetAddress,
  tokenId,
  assetAddress,
  collectionId,
  sendBackTo = ''
) {
  try {
    amount = roundNumber(mulBy(amount, 10 ** decimals), 0);
    console.log(assetAddress, tokenId, paymentAssetAddress, amount);
    var messageHash = window.web3.utils.soliditySha3(
      assetAddress,
      tokenId,
      paymentAssetAddress,
      amount
    );
    var account = window.ethereum.selectedAddress;
    const fixedPriceSignature = await window.web3.eth.personal.sign(
      messageHash,
      account
    );
    await updateSignature(collectionId, fixedPriceSignature);
    if (sendBackTo == 'update') {
      return window.updateSignFixedSuccess(collectionId);
    } else {
      return window.bidSignFixedSuccess(collectionId);
    }
  } catch (err) {
    console.error(err);
    if (sendBackTo == 'update') {
      return window.updateSignFixedFailed(err['message']);
    } else {
      return window.bidSignFixedFailed(err['message']);
    }
  }
};

// buyingAssetType = 1 # 721
// buyingAssetType = 0 # 1155
window.buyAsset = async function buyAsset(
  assetOwner,
  buyingAssetType,
  buyingAssetAddress,
  tokenId,
  unitPrice,
  buyingAssetQty,
  paymentAmt,
  paymentAssetAddress,
  decimals,
  sellerSign,
  collectionId
) {
  try {
    paymentAmt = roundNumber(mulBy(paymentAmt, 10 ** decimals), 0);
    unitPrice = roundNumber(mulBy(unitPrice, 10 ** decimals), 0);
    var compiledContractDetails = getContractABIAndBytecode(
      tradeContractAddress,
      'trade'
    );
    var abi = compiledContractDetails['compiled_contract_details']['abi'];
    var contract = await new window.web3.eth.Contract(
      abi,
      tradeContractAddress
    );
    var account = window.ethereum.selectedAddress;
    // supply, tokenURI, royalty needs to be passed but WILL NOT be used by the Contract
    var supply = 0;
    var tokenURI = 'abcde';
    var walletAddress = [];
    var royalties = [];
    var orderStruct = [
      assetOwner,
      account,
      paymentAssetAddress,
      buyingAssetAddress,
      buyingAssetType,
      unitPrice,
      paymentAmt,
      tokenId,
      supply,
      tokenURI,
      walletAddress,
      royalties,
      buyingAssetQty,
    ];
    var gasPrices = await estimateGas();
    let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }

    console.log(gasPrices);
    console.log(orderStruct, sellerSign);
    var receipt = await contract.methods
      .buyAsset(orderStruct, splitSign(sellerSign))
      .send({
        from: account,
        // gas: 516883,
        ...polygonSelect
      });
    await updateCollectionBuy(
      collectionId,
      buyingAssetQty,
      receipt.transactionHash
    );
    return window.buyPurchaseSuccess(collectionId);
  } catch (err) {
    console.error(err);
    return window.buyPurchaseFailed(err['message']);
  }
};

window.getTokenId = function getTokenId(receipt, buyingAssetType) {
  if (buyingAssetType == 2) {
    var tokenId = receipt.events[0].raw.data.slice(0, 66);
  } else {
    var tokenId = receipt.events[0].raw.topics[3];
  }
  return window.web3.utils.toDecimal(tokenId);
};

window.MintAndBuyAsset = async function MintAndBuyAsset(
  assetOwner,
  buyingAssetType,
  buyingAssetAddress,
  tokenId,
  unitPrice,
  buyingAssetQty,
  paymentAmt,
  paymentAssetAddress,
  decimals,
  sellerSign,
  collectionId,
  tokenURI,
  wallet_address,
  royalties,
  sharedCollection,
  supply,
  trade_address
) {
  try {
    paymentAmt = roundNumber(mulBy(paymentAmt, 10 ** decimals), 0);
    unitPrice = roundNumber(mulBy(unitPrice, 10 ** decimals), 0);
    var buyingAssetType = buyingAssetType + 2; // BuyAssetType -> 3: Lazy721 , 2: Lazy1155, 1:721, 0: 1155
    var compiledContractDetails = getContractABIAndBytecode(
      tradeContractAddress,
      'trade'
    );
    var abi = compiledContractDetails['compiled_contract_details']['abi'];
    var contract = await new window.web3.eth.Contract(
      abi,
      tradeContractAddress
    );
    var account = window.ethereum.selectedAddress;
    var orderStruct = [
      assetOwner,
      account,
      paymentAssetAddress,
      buyingAssetAddress,
      buyingAssetType,
      unitPrice,
      paymentAmt,
      tokenId,
      supply,
      tokenURI,
      wallet_address,
      royalties,
      buyingAssetQty,
    ];
    console.log('---orderStruct---');
    console.log(orderStruct);
    // ownerSign -> selleraddress & URI
    var gasPrices = await estimateGas();
    let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }

    //var ownerSign = await sign_metadata_with_creator(assetOwner, tokenURI, collectionId, trade_address);

    var receipt = await contract.methods
      .mintAndBuyAsset(
        orderStruct,
        //splitSign(ownerSign),
        splitSign(sellerSign)
      )
      .send({
        from: account,
        // gas: 916883,
        ...polygonSelect
      });
    //var tokenId = parseInt(receipt.events.BuyAsset.returnValues['tokenId'])
    tokenId = getTokenId(receipt, buyingAssetType);
    await updateCollectionBuy(
      collectionId,
      buyingAssetQty,
      receipt.transactionHash,
      tokenId
    );
    return window.buyPurchaseSuccess(collectionId);
  } catch (err) {
    console.error(err);
    return window.buyMintAndPurchaseFailed(err['message']);
  }
};

window.MintAndAcceptBid = async function MintAndAcceptBid(
  buyer,
  buyingAssetType,
  buyingAssetAddress,
  tokenId,
  paymentAmt,
  buyingAssetQty,
  paymentAssetAddress,
  decimals,
  buyerSign,
  collectionId,
  bidId,
  tokenURI,
  wallet_address,
  royalties,
  sharedCollection,
  supply,
  trade_address
) {
  try {
    console.log(tokenURI, wallet_address, royalties, sharedCollection);
    paymentAmt = roundNumber(mulBy(paymentAmt, 10 ** decimals), 0);
    var unitPrice = 1;
    var buyingAssetType = buyingAssetType + 2; // BuyAssetType -> 3: Lazy721 , 2: Lazy1155, 1:721, 0: 1155
    var compiledContractDetails = getContractABIAndBytecode(
      tradeContractAddress,
      'trade'
    );
    var abi = compiledContractDetails['compiled_contract_details']['abi'];
    var contract = await new window.web3.eth.Contract(
      abi,
      tradeContractAddress
    );
    var account = window.ethereum.selectedAddress;
    //token ID calculating
    window.contract721 = await getContract(
      buyingAssetAddress,
      'nft721',
      sharedCollection
    );

    var orderStruct = [
      account,
      buyer,
      paymentAssetAddress,
      buyingAssetAddress,
      buyingAssetType,
      unitPrice,
      paymentAmt,
      tokenId,
      supply,
      tokenURI,
      wallet_address,
      royalties,
      buyingAssetQty,
    ];
    console.log(orderStruct);
    var gasPrices = await estimateGas();
    let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }

    // ownerSign -> selleraddress & URI
    //var ownerSign = await sign_metadata_with_creator(account, tokenURI, collectionId, trade_address);
    //console.log(ownerSign)
    var receipt = await contract.methods
      .mintAndExecuteBid(
        orderStruct,
        //splitSign(ownerSign),
        splitSign(buyerSign)
      )
      .send({
        from: account,
        // gas: 916883,
        ...polygonSelect
      });
    //var tokenId = parseInt(receipt.events.ExecuteBid.returnValues['tokenId'])
    tokenId = getTokenId(receipt, buyingAssetType);
    await updateCollectionSell(
      collectionId,
      buyer,
      bidId,
      receipt.transactionHash,
      tokenId
    );
    return window.acceptBidSuccess(collectionId);
  } catch (err) {
    console.error(err);
    return window.acceptBidFailed(err['message']);
  }
};

window.executeBid = async function executeBid(
  buyer,
  buyingAssetType,
  buyingAssetAddress,
  tokenId,
  paymentAmt,
  buyingAssetQty,
  paymentAssetAddress,
  decimals,
  buyerSign,
  collectionId,
  bidId
) {
  try {
    paymentAmt = roundNumber(mulBy(paymentAmt, 10 ** decimals), 0);
    var unitPrice = 1;
    var compiledContractDetails = getContractABIAndBytecode(
      tradeContractAddress,
      'trade'
    );
    var abi = compiledContractDetails['compiled_contract_details']['abi'];
    var contract = await new window.web3.eth.Contract(
      abi,
      tradeContractAddress
    );
    var account = window.ethereum.selectedAddress;
    // supply, tokenURI, royalty needs to be passed but WILL NOT be used by the Contract
    var supply = 0;
    var tokenURI = 'abcde';
    var walletAddress = [];
    var royalties = [];
    var orderStruct = [
      account,
      buyer,
      paymentAssetAddress,
      buyingAssetAddress,
      buyingAssetType,
      unitPrice,
      paymentAmt,
      tokenId,
      supply,
      tokenURI,
      walletAddress,
      royalties,
      buyingAssetQty,
    ];
    var gasPrices = await estimateGas();
    let polygonSelect = {};

    if(isPolygon) {
      polygonSelect = gasPrices;
    }

    var receipt = await contract.methods
      .executeBid(orderStruct, splitSign(buyerSign))
      .send({
        from: account,
        // gas: 516883,
        ...polygonSelect
      });
    await updateCollectionSell(
      collectionId,
      buyer,
      bidId,
      receipt.transactionHash
    );
    return window.acceptBidSuccess(collectionId);
  } catch (err) {
    console.error(err);
    return window.acceptBidFailed(err['message']);
  }
};

function getCurrentAccount() {
  return window.ethereum.selectedAddress;
}

window.ethBalance = async function ethBalance() {
  var account = window.ethereum.selectedAddress;
  var bal = await window.web3.eth.getBalance(account);
  var ethBal = roundNumber(web3.utils.fromWei(bal, 'ether'), 4);
  return ethBal;
};

window.updateEthBalance = async function updateEthBalance() {
  var ethBal = await window.ethBalance();
  $('.curBalance').html(ethBal + 'ETH');
  $('.curEthBalance').text(ethBal);
};

window.tokenBalance = async function tokenBalance(contractAddress, decimals) {
  var abi = [
    {
      constant: true,
      inputs: [{ name: '_owner', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: 'balance', type: 'uint256' }],
      payable: false,
      type: 'function',
    },
  ];
  var contract = await new window.web3.eth.Contract(abi, contractAddress);
  var account = window.ethereum.selectedAddress;
  var balance = await contract.methods.balanceOf(account).call();
  console.log('address:' + contractAddress)
  console.log('test balance:' + balance)
  balance = roundNumber(divBy(balance, 10 ** decimals), 4);
  return balance;
};

window.getNetworkType = async function getNetworkType() {
  var type = await web3.eth.net.getNetworkType();
  return type;
};

function showTermsCondition(account, ethBal, networkType) {
  var account = account || window.ethereum.selectedAddress;
  console.log('showTermsCondition: ', account);
  // $("#terms-and-condition").modal("show")
  $.magnificPopup.open({
    closeOnBgClick: false,
    enableEscapeKey: false,
    items: {
      src: '#terms-and-condition',
    },
    type: 'inline',
  });
  $('#account').val(account);
  $('#eth_balance_tc').val(ethBal);
  $('#network_type').val(networkType);
}

async function load(wallet, shoulDestroySession = false, type= null) {
  const walletlessLogin = window.getCookie("walletless_login")

  console.log('shoulDestroySession: ' + shoulDestroySession, walletlessLogin)
  if (window.ethereum) {
    if(!walletlessLogin) {
      await loadWeb3(wallet);
    }
  
    var account = window.ethereum.selectedAddress;
    var networkType = await getNetworkType();
    var ethBal = await ethBalance();
    const isValidUserResp = await isValidUser(account, '');
    const walletlessLogin = window.getCookie("walletless_login")
    console.log(walletlessLogin, 'cookie')
    console.log('ethBal :' + ethBal)
    if (walletlessLogin && walletlessLogin == 'true') {
      await createUserSession(account, ethBal, shoulDestroySession, type);
      // window.location.href = oriRoute;
      return;
    }
    if (isValidUserResp.user_exists) {

      console.log('ethBal user_exists:' + ethBal)
      if (!walletlessLogin) {
        await createUserSession(account, ethBal, shoulDestroySession);
      }
      if (shoulDestroySession) {
        window.location.href = oriRoute;
      } else {
        return true;
      }
    } else {
      if (gon.session) {
        if(!walletlessLogin)
        {
          if (account) {
            await destroySession();
          }
          window.location.reload();
        }
      } else {
        showTermsCondition(account, ethBal, networkType);
        return false;
      }
    }
  }
}

window.disconnect = async function disconnect(address) {
  await destroySession();
  window.location.href = '/';
};

async function destroySession() {
  if (gon.session) {
    console.log('IN DESTROY: ', gon.session);
    await destroyUserSession(account);
  }
}

window.connect = async function connect(wallet, type= null) {
  if (typeof web3 === 'undefined' && mobileCheck()) {
    window
      .open(`https://metamask.app.link/dapp/` + window.location.href, '_blank')
      .focus();
    return;
  } else if (typeof web3 !== 'undefined') {

    const status = await load(wallet, null,  type);
    if (status) {

      window.location.reload();
    }
  } else {
    toastr.error('Please install Metamask Extension to your browser.');
  }
};

window.proceedWithLoad = async function proceedWithLoad() {
  var account = $('#account').val();
  const ethBal = $('#eth_balance').text();
  const networkType = $('#network_type').val();
  const walletlessLogin = window.getCookie("walletless_login")
  if ($('#condition1').is(':checked') && $('#condition2').is(':checked') && !walletlessLogin) {
    await createUserSession(account, ethBal, networkType);
    window.location.reload();
  } else {
    toastr.error('Please accept the conditions to proceed');
  }
};

window.loadUser = async function loadUser() {
  if (window.ethereum && gon.session) {
    load();
  }
};

// Switching Networks
window.switchNetworks = async function switchNetworks(chain, originalRoute) {
  oriRoute = originalRoute
  if (chain == 'goerli') {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x5' }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x5', // A 0x-prefixed hexadecimal string
                chainName: 'Goerli Testnet',
                nativeCurrency: {
                  name: 'Ethereum',
                  symbol: 'ETH', // 2-6 characters long
                  decimals: 18,
                },
                rpcUrls: ['https://goerli.infura.io/v3/'],
                blockExplorerUrls: ['https://goerli.etherscan.io'],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  } else if (chain == 'ethereum') {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x1', // A 0x-prefixed hexadecimal string
                chainName: 'Ethereum Mainnet',
                nativeCurrency: {
                  name: 'Ethereum',
                  symbol: 'ETH', // 2-6 characters long
                  decimals: 18,
                },
                rpcUrls: [
                  'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
                ],
                blockExplorerUrls: ['https://etherscan.io/'],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  } else if (chain == 'bsc-test') {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x61' }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x61', // A 0x-prefixed hexadecimal string
                chainName: 'BSC Testnet',
                nativeCurrency: {
                  name: 'Binance Coin',
                  symbol: 'BNB', // 2-6 characters long
                  decimals: 18,
                },
                rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  } else if (chain == 'binance') {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x38', // A 0x-prefixed hexadecimal string
                chainName: 'BSC Mainnet',
                nativeCurrency: {
                  name: 'Binance Coin',
                  symbol: 'BNB', // 2-6 characters long
                  decimals: 18,
                },
                rpcUrls: ['https://bsc-dataseed1.binance.org/'],
                blockExplorerUrls: ['https://bscscan.com/'],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  } else if (chain == 'matic-test') {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x13881' }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x13881', // A 0x-prefixed hexadecimal string
                chainName: 'Polygon Tesnet',
                nativeCurrency: {
                  name: 'Matic',
                  symbol: 'MATIC', // 2-6 characters long
                  decimals: 18,
                },
                rpcUrls: ['https://matic-mumbai.chainstacklabs.com/'],
                blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  } else if (chain == 'matic') {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x89' }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x89', // A 0x-prefixed hexadecimal string
                chainName: 'Polygon Network',
                nativeCurrency: {
                  name: 'Matic',
                  symbol: 'MATIC', // 2-6 characters long
                  decimals: 18,
                },
                rpcUrls: ['https://matic-mainnet.chainstacklabs.com'],
                blockExplorerUrls: ['https://polygonscan.com/'],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  }
  // setTimeout(() => {
  // redirectFunction(oriRoute);
  // }, 5000)
};

function redirectFunction(oriRoute) {
  window.location.href = oriRoute;
}

async function  estimateGas () {
  // get max fees from gas station

  let maxFeePerGas = window.web3.utils.toBN('40000000000') // fallback to 40 gwei
  let maxPriorityFeePerGas = window.web3.utils.toBN('40000000000') // fallback to 40 gwei

  let currentStation = 'https://gasstation-mainnet.matic.network/v2';

  if(isPolygon && !isPolygonLive) {
    currentStation = 'https://gasstation-mumbai.matic.today/v2';
  }

  try {
      const { data } = await axios({
          method: 'get',
          url: currentStation
      })
      maxFeePerGas = window.web3.utils.toWei(
          Math.ceil(data.fast.maxFee) + '',
          'gwei'
      )
      maxPriorityFeePerGas = window.web3.utils.toWei(
          Math.ceil(data.fast.maxPriorityFee) + '',
          'gwei'
      )
  } catch {
      // ignore
  }

  // send tx with custom gas
  return {
    gas: 516883,
    maxFeePerGas,
    maxPriorityFeePerGas
  }
}

function gasPrice() {
  var init_gasPrice = '';
  var gasLimit;
  if (gon.tokenSymbol == 'WMATIC') {
    init_gasPrice = '6000000000000';
    gasLimit = 9;
  } else if (gon.tokenSymbol == 'WETH') {
    init_gasPrice = '4000000000000';
    gasLimit = 8;
  } else if (gon.tokenSymbol == 'WBNB') {
    init_gasPrice = '10000000000';
    gasLimit = 8;
  }

  try {
    var request = $.ajax({
      url: `/gas_price`,
      async: false,
      type: 'GET',
    });
    request.done(function (msg) {
      console.log(msg);
      console.log('Get Fastest Value from the API');
      if (msg['gas_price'] != '') {
        init_gasPrice = msg['gas_price']['fastest'] * 10 ** parseInt(gasLimit);
      }
    });
    request.fail(function (jqXHR, textStatus) {
      console.log('Failed to get fastest value');
    });
  } catch (err) {
    console.error(err);
  }
  console.log(init_gasPrice);
  return init_gasPrice;
}

async function loadAddress() {
  await loadWeb3();
}

$(function () {
  loadAddress();
});
if (window.ethereum) {
  window.ethereum.on('accountsChanged', function (acc) {
    if (window.ethereum && gon.session) {
      load(undefined,true);
    }
  });
  window.ethereum.on('chainChanged', function (chainId) {
    if (window.ethereum && gon.session) {
      console.log('Chain Changed')
      load(undefined,true);
    }
  });
}
window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
