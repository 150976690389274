window.SeatComponent = {
  seatClipboard(elemId) {
    let elem = window.Lib.parseHtml(`
      <div class="head">
        <p>${elemId}</p>
      </div>
      <div class="body">
        <button type="button">${
          window.Lib.isSeatSelected(`${elemId}`) ? "Unselect" : "Select"
        }</button>
      </div>
    `);

    let text = elem.querySelector(".head p").textContent;
    let button = elem.querySelector("button");

    button.addEventListener("click", () => {
      if (window.Lib.isSeatSelected(text)) {
        window.Lib.removeSelectedSeat(text);
        button.textContent = "Select";
      } else {
        window.Lib.addToSelectedSeats(text);
        button.textContent = "Unselect";
        let seatClipboard = document.getElementById("seat-clipboard");
        seatClipboard.classList.remove("active");
      }
    });

    return elem;
  },
};
