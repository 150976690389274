import { Controller } from "stimulus";
import axios from "axios";
import countdown from "countdown";

// Connects to data-controller="user-tickets"
export default class extends Controller {
  static targets = [
    "email",
    "verification",
    "confirm",
    "loading",
    "resendBtn",
    "confirmParagraph",
  ];
  connect() {}

  initialize() {

  }

  async emailSubmit(e) {
    e.preventDefault();
    const emailForm = document.getElementById("walletless_connect_email_form");
    this.confirmTarget.disabled = true;
    this.loadingTarget.classList.remove("hidden");
    const verificationForm = document.getElementById(
      "walletless_connect_verification_form"
    );
    const element = document.querySelector("[name=csrf-token]");
    let token;
    if (element instanceof HTMLMetaElement) {
      token = element.content;
    }
    const formData = new FormData(e.target);

    axios
      .post("/walletless-login", formData, {
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
          proccessData: true,
        },
      })
      .then(
        (response) => {
          emailForm.style.transform = "translateX(-200%)";
          toastr.success("Email sent successfully");
          verificationForm.style.transform = "translateX(0";
          this.confirmTarget.disabled = false;
          this.loadingTarget.classList.add("hidden");
          this.resendBtnTarget.disabled = true;
          console.log(this.emailTarget.value);
          this.confirmParagraphTarget.innerHTML = `We sent a confirmation code on your email <span class="text-[#4375af]">${this.emailTarget.value}</span><br>Please check your junk / spam folders if you do not receive your OTP code.`;
          this.resendCounter(response.data.code_invalid_by);
        },
        (error) => {
          this.confirmTarget.disabled = false;
          this.loadingTarget.classList.add("hidden");
          console.log(error.response);
          toastr.error(error.response.data.message);
        }
      );
  }

  async verificationSubmit(e) {
    e.preventDefault();
    const codeValue = document.getElementById("verification-code").value;
    const verificationForm = document.getElementById(
      "walletless_connect_verification_form"
    );
    const element = document.querySelector("[name=csrf-token]");
    const res = await fetch(`/walletless-mola-subscriber-check/${codeValue}`);
    let token;
    if (element instanceof HTMLMetaElement) {
      token = element.content;
    }
    axios
      .put(
        `/email-verification-code-login?verification_code=${codeValue}`,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
            proccessData: true,
          },
        }
      )
      .then(
        (res) => {
          toastr.success("Verification successful");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        },
        (err) => {
          toastr.error(err.response.data.message);
        }
      );
  }

  resendCode(e) {
    e.preventDefault();
    axios
      .get("/walletless-login-resend-code")
      .then(
        (res) => {
          this.resendBtnTarget.disabled = true;
          this.resendCounter(res.data.code_invalid_by);
          toastr.success("Email sent successfully");
        },
        (error) => {
          toastr.error(error.response.data.message);
        }
      );
  }

  resendCounter(time) {
    const resendBtn = document.querySelector("#resendBtn");
    const deadlineTime = new Date(time)
    deadlineTime.setSeconds(deadlineTime.getSeconds() + 3)
    const deadline = deadlineTime.getTime()
    console.log(deadline)


    const x = setInterval(function () {
      const now = new Date().getTime();
      const t = deadline - now;
      var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((t % (1000 * 60)) / 1000);

      resendBtn.innerText = `Resend Verification Code in ${minutes}:${seconds}`;
      if (t < 0) {
        clearInterval(x);
        resendBtn.disabled = false;

        resendBtn.innerText = "Resend Verification Code";
      }
    }, 1000);
  }

  // later on this timer may be stopped
  // window.clearInterval(timerId);
}
