import axios from "axios";

const CustomApi = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  },
});

window.Lib = {
  globalParams: {
    selectedSeats: [],
  },

  addToSelectedSeats(text) {
    if (
      this.globalParams.selectedSeats.length >= window.Ticket.maximumSelection
    ){
      let x = this.globalParams.selectedSeats.length - +(window.Ticket.maximumSelection);
      for(let i = 0; i <= x; i++){
        this.globalParams.selectedSeats.shift();
      }
    }
    if (text.startsWith("stand")) {
      let NSelected = this.globalParams.selectedSeats.length;

      for (let i = 0; i < window.Ticket.maximumSelection - NSelected; i++) {
        this.globalParams.selectedSeats.push(text);
      }
    } else if (text.startsWith("table") && !text.startsWith("table-seat")) {
      let arr = [];
      let letter = text.match(/table-\w+-T\d+/)[0];
      letter = letter.slice(6, letter.length);

      let i = 0;
      while (
        i < document.querySelectorAll(`[id^='table-seat-${letter}']`).length &&
        window.Ticket.maximumSelection -
          this.globalParams.selectedSeats.length >=
          0
      ) {
        if (
          this.globalParams.selectedSeats.length ==
          window.Ticket.maximumSelection
        )
          this.globalParams.selectedSeats.shift();

        let seatId = `table-seat-${letter}-${i + 1}`;
        if (!this.globalParams.selectedSeats.includes(seatId))
          this.globalParams.selectedSeats.push(seatId);

        i++;
      }
    } else this.globalParams.selectedSeats.push(text);
    this.performSeatSelectionSideEffect(true);
  },

  colorMapItem(elem) {
    window.board
      .querySelectorAll("[id^='seat'] , [id^='table'], [id^='stand']")
      .forEach((elem) => {
        if (elem.tagName === "g")
          elem
            .querySelector("circle, rect")
            .setAttribute("fill", "transparent");
        else elem.setAttribute("fill", "transparent");
      });
    if (elem) {
      if (elem.tagName === "g")
        elem.querySelector("circle, rect").setAttribute("fill", "#97c7ff");
      else elem.setAttribute("fill", "#97c7ff");
    }
  },

  getBottomAndCenterPosition(elem) {
    let { left, width, bottom } = elem.getBoundingClientRect();

    return {
      x: left + width / 2,
      y: bottom,
    };
  },

  getLetterFromNum(num) {
    let a = ((num - 1) % 26) + 1;
    let b = Math.floor((num - 1) / 26) || "";

    if (typeof a == "number") a = String.fromCharCode(64 + a);
    if (typeof b == "number") b = String.fromCharCode(64 + b);

    return b.concat(a);
  },

  getRowColorRgb(rowColor) {
    if (!rowColor || rowColor == "none") return "rgba(255, 255, 255, 0)";
    if (rowColor == "grey") return "rgba(127, 127, 127, 0.1)";
    if (rowColor == "yellow") return "rgba(255, 255, 0, 0.1)";
    if (rowColor == "green") return "rgba(0, 255, 0, 0.1)";
    if (rowColor == "blue-green") return "rgba(0, 255, 255, 0.1)";
    if (rowColor == "blue") return "rgba(0, 0, 255, 0.1)";
    if (rowColor == "purple") return "rgba(255, 0, 255, 0.1)";
    if (rowColor == "red") return "rgba(255, 0, 0, 0.1)";
    return "rgba(255, 255, 255, 0)";
  },

  getSelectedSeats() {
    return this.globalParams;
  },

  isSeatSelected(text) {
    return this.globalParams.selectedSeats.includes(text);
  },

  moveSeatClipboard(elem) {
    let { x, y } = this.getBottomAndCenterPosition(elem);

    let elemId = "";
    let type = "";

    if (elem.id.startsWith("stand")) {
      elemId = elem.id.slice(6);
      type = "Stand";
    } else if (elem.id.startsWith("seat")) {
      elemId = elem.id.slice(5);
      type = "Seat";
    } else if (elem.id.startsWith("table-seat")) {
      elemId = elem.id.slice(11);
      type = "Table-seat";
    } else if (elem.id.startsWith("table")) {
      elemId = elem.id.slice(6);
      type = "Table";
    }

    window.seatClipBoard.innerHTML = "";
    window.seatClipBoard.append(window.SeatComponent.seatClipboard(elem.id));

    if (
      y + window.seatClipBoard.getBoundingClientRect().height + 30 >
      window.innerHeight
    )
      y =
        y -
        elem.getBoundingClientRect().height -
        window.seatClipBoard.getBoundingClientRect().height -
        2;

    if (window.seatClipBoard.classList.contains("active"))
      window.seatClipBoard.style.transition = "";
    else
      window.seatClipBoard.style.transition = "opacity 500ms, visibility 500ms";

    let left = window.seatClipBoard.getBoundingClientRect().width / 2;

    window.seatClipBoard.style.transform = `translate(${x - left}px, ${
      y + 2
    }px)`;
    window.seatClipBoard.classList.add("active");
  },

  parseHtml(htmlstring) {
    let div = document.createElement("div");
    let fragement = new DocumentFragment();

    div.innerHTML = htmlstring;
    fragement.append(...div.children);

    return fragement;
  },

  performSeatSelectionSideEffect(added) {
    console.log(this.globalParams.selectedSeats);
    window.totalToSelect.innerText = `${this.globalParams.selectedSeats.length}/${window.Ticket.maximumSelection}`;
    if (
      this.globalParams.selectedSeats.length == window.Ticket.maximumSelection
    ) {
      window.checkoutBtn.classList.remove("disabled");
    } else window.checkoutBtn.classList.add("disabled");

    window.Board.draw();

    this.globalParams.selectedSeats.forEach((text) => {
      let elem = document.getElementById(text);
      if (elem.tagName === "g")
        elem.querySelector("circle, rect").setAttribute("fill", "#97c7ff");
      else elem.setAttribute("fill", "#97c7ff");
    });
  },

  removeSeatClipboard() {
    window.seatClipBoard.classList.remove("active");
  },

  removeSelectedSeat(text) {
    this.globalParams.selectedSeats = this.globalParams.selectedSeats.filter(
      (e) => e !== text
    );
    this.performSeatSelectionSideEffect(false);
  },

  rowIdSortFn(a, b) {
    if (!/Row-group-\d+/.test(a) || !/Row-group-\d+/.test(b)) return 0;
    let numA = a.match(/\d+/)[0];
    let numB = b.match(/\d+/)[0];

    return numA - numB;
  },

  startContinousScaleDownAfter500ms() {
    this.globalParams.scaleDownSetTime = setTimeout(() => {
      this.globalParams.scaleDownSetInerval = setInterval(() => {
        window.State.setScale(window.State.getScale() - 0.1);
      }, 25);
    }, 300);
  },

  startContinousScaleUpAfter500ms() {
    this.globalParams.scaleUpSetTime = setTimeout(() => {
      this.globalParams.scaleUpSetInerval = setInterval(() => {
        window.State.setScale(window.State.getScale() + 0.1);
      }, 25);
    }, 300);
  },

  stopContinousScale() {
    if (this.globalParams.scaleDownSetTime !== undefined)
      clearTimeout(this.globalParams.scaleDownSetTime);
    if (this.globalParams.scaleDownSetInerval !== undefined)
      clearInterval(this.globalParams.scaleDownSetInerval);
    if (this.globalParams.scaleUpSetTime !== undefined)
      clearTimeout(this.globalParams.scaleUpSetTime);
    if (this.globalParams.scaleUpSetInerval !== undefined)
      clearInterval(this.globalParams.scaleUpSetInerval);
  },

  async submitSeats() {
    let token = document.querySelector("[name=csrf-token]");
    const saveSeat = await fetch("/event-ticket-seats", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authenticity_token: token.content,
        collection_id: window.collectionId,
        event_id: window.eventId,
        selected_seats: this.globalParams.selectedSeats,
        payment_status: 'pending',
        ticket_type: window.ticketType.innerText
      }),
    });
    const response = await saveSeat.json();
    if (response.status === "unprocessable_entity") {
      alert("Something went wrong while saving your seats");
    } else {
      window.location.reload()
    }
  },
};
